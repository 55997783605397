<template>
  <ul class="link-box">
    <!-- <li @click="unionClick">
      <div class="top title">{{ $t('deposit.default.channels.unionPay') }}</div>
      <div class="info">
        <div class="logo diamond unionpay"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            1 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="chinaUnionClick" data-testid="chinaUnion">
      <div class="top title">{{ $t('deposit.default.channels.unionPayCN') }}</div>
      <div class="info">
        <div class="logo diamond unionpay_china"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li v-if="countryCode === nlCountryCode" @click="iDealClick" data-testid="mobile">
      <div class="top title">{{ $t('deposit.default.channels.ideal') }}</div>
      <div class="info">
        <div class="logo circle bank"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="indonesiaBankwireClick" v-if="countryCode === idnCountryCode" data-testid="indonesiaBankwire">
      <div class="top title">{{ $t('deposit.default.channels.indonesiaInstant') }}</div>
      <div class="info">
        <div class="logo circle bankIdnPrimary"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="indonesiaXpayClick" v-if="countryCode === idnCountryCode" data-testid="indonesiaXpay">
      <div class="top title">{{ $t('deposit.default.channels.indonesiaInstant') }}</div>
      <div class="info">
        <div class="logo circle bankIdnSecondary"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="indonesiaZotapayClick" v-if="countryCode === idnCountryCode" data-testid="indonesiaZotapay">
      <div class="top title">{{ $t('deposit.default.channels.indonesiaInstant') }}</div>
      <div class="info">
        <div class="logo circle bankIdnTertiary"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="malaysiaXpayClick" v-if="countryCode == mysCountryCode" data-testid="malaysiaXpay">
      <div class="top title">{{ $t('deposit.default.channels.malayInstant') }}</div>
      <div class="info">
        <div class="logo circle bank"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="malaysiaPaytrustClick" v-if="countryCode === mysCountryCode" data-testid="malaysiaPaytrust">
      <div class="top title">{{ $t('deposit.default.channels.malayInstant') }}</div>
      <div class="info">
        <div class="logo circle bankMysPrimary"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="malaysiaZotapayClick" v-if="countryCode === mysCountryCode" data-testid="malaysiaZotapay">
      <div class="top title">{{ $t('deposit.default.channels.malayInstant') }}</div>
      <div class="info">
        <div class="logo circle bankMysSecondary"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="ebuyClick" v-if="countryCode === krCountryCode">
      <div class="top title" :class="{ titleSmall: ['pt', 'es'].includes(lang) }">
        {{ $t('common.withdrawChannel.southkoreabanktransfer') }}
      </div>
      <div class="info">
        <div class="logo diamond ebuy"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="canadaLocalBankClick" v-if="countryCode === canCountryCode" data-testid="canadaLocalBank">
      <div class="top title">{{ $t('deposit.default.channels.canada') }}</div>
      <div class="info">
        <div class="logo circle bankInterac"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="vietnamZotapayClick" v-if="countryCode === vnmCountryCode" data-testid="vietnamZotapay">
      <div class="top title">{{ $t('deposit.default.channels.vietInstant') }}</div>
      <div class="info">
        <div class="logo circle bankVumSecondary"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="vietnamXpayClick" v-if="countryCode === vnmCountryCode" data-testid="vietnamXpay">
      <div class="top title">{{ $t('deposit.default.channels.vietInstant') }}</div>
      <div class="info">
        <div class="logo circle bankVumPrimary"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="zotaClick" v-if="countryCode === phpCountryCode" data-testid="phpZota">
      <div class="top title">{{ $t('deposit.default.channels.philippineInstant') }}</div>
      <div class="info">
        <div class="logo circle bank"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->

    <!-- <li @click="thailandXpayClick" v-if="countryCode === thaCountryCode">
      <div class="top title">{{ $t('deposit.default.channels.thaiInstant') }}</div>
      <div class="info">
        <div class="logo circle bankTha"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="zotaClick" v-if="countryCode === thaCountryCode" data-testid="thaiZota">
      <div class="top title">{{ $t('deposit.default.channels.thaiInstant') }}{{ showQRCode }}</div>
      <div class="info">
        <div class="logo circle bankTha"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="zotaClick" v-if="countryCode === ngaCountryCode" data-testid="ngaZota">
      <div class="top title">{{ $t('deposit.default.channels.nigeriaInstant') }}</div>
      <div class="info">
        <div class="logo circle bankNga"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            1-2 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="PaystackClick" v-if="countryCode == ngaCountryCode" data-testid="ngaPaystack">
      <div class="top title">{{ $t('deposit.default.channels.nigeriaLocalPayment') }}</div>
      <div class="info">
        <div class="logo circle bankNga"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            1-2 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="indiaZotapayClick" v-if="countryCode === inCountryCode">
      <div class="top title">{{ $t('deposit.default.channels.indiaInstant') }}</div>
      <div class="info">
        <div class="logo circle bankIda"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="southAfricaClick" v-if="countryCode === zarCountryCode" data-testid="southAfrica">
      <div class="top title">{{ $t('deposit.southAfrica.header') }}</div>
      <div class="info">
        <div class="logo circle bankZar"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="africaClick" v-if="!restrictedAfricaZotapay" :data-testid="africaZotapaySetup[countryCode].dataTestId">
      <div class="top title">{{ $t(africaZotapaySetup[countryCode].header) }}</div>
      <div class="info">
        <div class="logo circle" :class="africaZotapaySetup[countryCode].logo"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="localDepositorClick" v-if="localDepositorCountries && restrictedChina" data-testid="chinaUnion">
      <div class="top title">{{ $t('deposit.localdepositor.depositor') }}</div>
      <div class="info">
        <div class="logo circle bank"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            1 {{ $t('deposit.default.businessDays') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <li v-if="countryCode === ngaCountryCode" @click="nigeriaBankTransferClick" data-testid="nigeriaBankTransfer">
      <div class="top title">{{ $t('common.withdrawChannel.nigeriabanktransfer') }}</div>
      <div class="info">
        <div class="logo circle bankNga"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            {{ $t('deposit.default.withinOneBusinessDays') }}, 24/7
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <!-- https://suntontech.atlassian.net/browse/PWR-1683 -->
    <!-- <li v-if="countryCode === uaeCountryCode" @click="uaelocalbanktransferClick" data-testid="uaelocalbanktransfer">
      <div class="top title">{{ $t('common.withdrawChannel.uaelocalbanktransfer') }}</div>
      <div class="info">
        <div class="logo circle bank"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            {{ $t('deposit.default.withinOneBusinessDays') }}, 24/7
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- CPS Deposit List -->
    <template v-for="(payment, index) in cpsDepositList">
      <DepositListing
        :payment="payment"
        :key="'cps-' + index"
        v-if="permittedCpsLocalTransferPaymentMethods.includes(payment.payment_method)"
      ></DepositListing>
    </template>
  </ul>
</template>

<script>
import mixin from '@/mixins/page/deposit/deposit';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import DepositListing from '@/components/cps/DepositListing';

export default {
  mixins: [mixin, cpsMixin],
  components: { DepositListing },
  props: ['cpsDepositList'],
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    showQRCode() {
      return this.lang === 'th' ? '(QR Code)' : '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
</style>
